exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-beauty-cosmetic-shop-software-js": () => import("./../../../src/pages/beauty-cosmetic-shop-software.js" /* webpackChunkName: "component---src-pages-beauty-cosmetic-shop-software-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-customer-app-js": () => import("./../../../src/pages/customer-app.js" /* webpackChunkName: "component---src-pages-customer-app-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-driver-app-js": () => import("./../../../src/pages/driver-app.js" /* webpackChunkName: "component---src-pages-driver-app-js" */),
  "component---src-pages-food-delivery-app-development-js": () => import("./../../../src/pages/food-delivery-app-development.js" /* webpackChunkName: "component---src-pages-food-delivery-app-development-js" */),
  "component---src-pages-grocery-delivery-app-development-js": () => import("./../../../src/pages/grocery-delivery-app-development.js" /* webpackChunkName: "component---src-pages-grocery-delivery-app-development-js" */),
  "component---src-pages-home-kitchen-appliance-shop-management-software-js": () => import("./../../../src/pages/home-kitchen-appliance-shop-management-software.js" /* webpackChunkName: "component---src-pages-home-kitchen-appliance-shop-management-software-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-email-gateways-js": () => import("./../../../src/pages/integrations/email-gateways.js" /* webpackChunkName: "component---src-pages-integrations-email-gateways-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-integrations-map-js": () => import("./../../../src/pages/integrations/map.js" /* webpackChunkName: "component---src-pages-integrations-map-js" */),
  "component---src-pages-integrations-notification-tools-js": () => import("./../../../src/pages/integrations/notification-tools.js" /* webpackChunkName: "component---src-pages-integrations-notification-tools-js" */),
  "component---src-pages-integrations-payment-gateways-js": () => import("./../../../src/pages/integrations/payment-gateways.js" /* webpackChunkName: "component---src-pages-integrations-payment-gateways-js" */),
  "component---src-pages-manage-js": () => import("./../../../src/pages/manage.js" /* webpackChunkName: "component---src-pages-manage-js" */),
  "component---src-pages-on-demand-delivery-software-for-bakeries-js": () => import("./../../../src/pages/on-demand-delivery-software-for-bakeries.js" /* webpackChunkName: "component---src-pages-on-demand-delivery-software-for-bakeries-js" */),
  "component---src-pages-on-demand-delivery-software-for-cafe-and-coffee-shops-js": () => import("./../../../src/pages/on-demand-delivery-software-for-cafe-and-coffee-shops.js" /* webpackChunkName: "component---src-pages-on-demand-delivery-software-for-cafe-and-coffee-shops-js" */),
  "component---src-pages-on-demand-delivery-software-for-cloud-kitchen-js": () => import("./../../../src/pages/on-demand-delivery-software-for-cloud-kitchen.js" /* webpackChunkName: "component---src-pages-on-demand-delivery-software-for-cloud-kitchen-js" */),
  "component---src-pages-on-demand-delivery-software-for-dark-stores-js": () => import("./../../../src/pages/on-demand-delivery-software-for-dark-stores.js" /* webpackChunkName: "component---src-pages-on-demand-delivery-software-for-dark-stores-js" */),
  "component---src-pages-on-demand-delivery-software-solution-js": () => import("./../../../src/pages/on-demand-delivery-software-solution.js" /* webpackChunkName: "component---src-pages-on-demand-delivery-software-solution-js" */),
  "component---src-pages-on-demand-food-delivery-software-solution-js": () => import("./../../../src/pages/on-demand-food-delivery-software-solution.js" /* webpackChunkName: "component---src-pages-on-demand-food-delivery-software-solution-js" */),
  "component---src-pages-on-demand-grocery-delivery-software-solution-js": () => import("./../../../src/pages/on-demand-grocery-delivery-software-solution.js" /* webpackChunkName: "component---src-pages-on-demand-grocery-delivery-software-solution-js" */),
  "component---src-pages-on-demand-materials-delivery-software-solution-js": () => import("./../../../src/pages/on-demand-materials-delivery-software-solution.js" /* webpackChunkName: "component---src-pages-on-demand-materials-delivery-software-solution-js" */),
  "component---src-pages-on-demand-meat-delivery-software-js": () => import("./../../../src/pages/on-demand-meat-delivery-software.js" /* webpackChunkName: "component---src-pages-on-demand-meat-delivery-software-js" */),
  "component---src-pages-on-demand-medicine-delivery-software-solution-js": () => import("./../../../src/pages/on-demand-medicine-delivery-software-solution.js" /* webpackChunkName: "component---src-pages-on-demand-medicine-delivery-software-solution-js" */),
  "component---src-pages-on-demand-ordering-software-solution-js": () => import("./../../../src/pages/on-demand-ordering-software-solution.js" /* webpackChunkName: "component---src-pages-on-demand-ordering-software-solution-js" */),
  "component---src-pages-online-milk-delivery-software-js": () => import("./../../../src/pages/online-milk-delivery-software.js" /* webpackChunkName: "component---src-pages-online-milk-delivery-software-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-our-usps-js": () => import("./../../../src/pages/our-usps.js" /* webpackChunkName: "component---src-pages-our-usps-js" */),
  "component---src-pages-pizza-ordering-delivery-platform-js": () => import("./../../../src/pages/pizza-ordering-delivery-platform.js" /* webpackChunkName: "component---src-pages-pizza-ordering-delivery-platform-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-restaurant-ordering-and-delivery-solution-js": () => import("./../../../src/pages/restaurant-ordering-and-delivery-solution.js" /* webpackChunkName: "component---src-pages-restaurant-ordering-and-delivery-solution-js" */),
  "component---src-pages-support-center-js": () => import("./../../../src/pages/support-center.js" /* webpackChunkName: "component---src-pages-support-center-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-vendor-app-js": () => import("./../../../src/pages/vendor-app.js" /* webpackChunkName: "component---src-pages-vendor-app-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-blog-main-categories-js": () => import("./../../../src/templates/blog-main-categories.js" /* webpackChunkName: "component---src-templates-blog-main-categories-js" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-automated-grocery-delivery-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/benefits-of-automated-grocery-delivery-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-automated-grocery-delivery-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-boost-your-grocery-business-during-covid-19-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/boost-your-grocery-business-during-covid-19/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-boost-your-grocery-business-during-covid-19-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-boosting-restaurant-revenue-the-power-of-on-demand-delivery-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/boosting-restaurant-revenue-the-power-of-on-demand-delivery-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-boosting-restaurant-revenue-the-power-of-on-demand-delivery-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-build-a-hyperlocal-on-demand-delivery-model-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/build-a-hyperlocal-on-demand-delivery-model/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-build-a-hyperlocal-on-demand-delivery-model-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-build-an-on-demand-delivery-app-like-dunzo-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/build-an-on-demand-delivery-app-like-dunzo/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-build-an-on-demand-delivery-app-like-dunzo-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-covid-19-on-demand-delivery-business-opportunities-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/covid-19-on-demand-delivery-business-opportunities/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-covid-19-on-demand-delivery-business-opportunities-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-custom-vs-readymade-restaurant-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/custom-vs-readymade-restaurant-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-custom-vs-readymade-restaurant-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-dairy-profit-minimization-milk-delivery-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/dairy-profit-minimization-milk-delivery-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-dairy-profit-minimization-milk-delivery-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-develop-an-on-demand-flower-delivery-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/develop-an-on-demand-flower-delivery-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-develop-an-on-demand-flower-delivery-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-develop-on-demand-parcel-delivery-app-like-swiggy-genie-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/develop-on-demand-parcel-delivery-app-like-swiggy-genie/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-develop-on-demand-parcel-delivery-app-like-swiggy-genie-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digital-solution-to-engage-customer-for-on-demand-delivery-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/digital-solution-to-engage-customer-for-on-demand-delivery-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digital-solution-to-engage-customer-for-on-demand-delivery-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-food-delivery-business-need-an-online-platform-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/food-delivery-business-need-an-online-platform/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-food-delivery-business-need-an-online-platform-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-food-delivery-solutions-for-single-restaurant-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/food-delivery-solutions-for-single-restaurant/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-food-delivery-solutions-for-single-restaurant-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-functionality-grocery-stores-need-to-generate-more-orders-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/functionality-grocery-stores-need-to-generate-more-orders/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-functionality-grocery-stores-need-to-generate-more-orders-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-set-up-online-ordering-for-restaurants-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/guide-to-set-up-online-ordering-for-restaurants/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-set-up-online-ordering-for-restaurants-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-saas-platform-streamlines-restaurant-operations-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-saas-platform-streamlines-restaurant-operations/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-saas-platform-streamlines-restaurant-operations-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-beat-the-competition-in-on-demand-food-delivery-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-to-beat-the-competition-in-on-demand-food-delivery-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-beat-the-competition-in-on-demand-food-delivery-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-get-more-customer-for-restaurant-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-to-get-more-customer-for-restaurant/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-get-more-customer-for-restaurant-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-start-online-meat-delivery-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-to-start-online-meat-delivery-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-start-online-meat-delivery-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-yelowxpress-helps-restaurant-increase-recurring-customers-revenue-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-yelowxpress-helps-restaurant-increase-recurring-customers-revenue/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-yelowxpress-helps-restaurant-increase-recurring-customers-revenue-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-implement-contactless-delivery-system-for-your-delivery-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/implement-contactless-delivery-system-for-your-delivery-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-implement-contactless-delivery-system-for-your-delivery-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-importance-of-parcel-delivery-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/importance-of-parcel-delivery-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-importance-of-parcel-delivery-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-improve-food-ordering-delivery-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/improve-food-ordering-delivery-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-improve-food-ordering-delivery-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-medicine-delivery-application-for-pharmaceutical-operations-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/medicine-delivery-application-for-pharmaceutical-operations/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-medicine-delivery-application-for-pharmaceutical-operations-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-multi-store-management-solution-for-grocery-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/multi-store-management-solution-for-grocery-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-multi-store-management-solution-for-grocery-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-on-demand-delivery-predictions-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/on-demand-delivery-predictions/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-on-demand-delivery-predictions-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-on-demand-food-delivery-system-for-restaurant-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/on-demand-food-delivery-system-for-restaurant-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-on-demand-food-delivery-system-for-restaurant-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-on-demand-medicine-delivery-app-development-for-pharmacy-store-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/on-demand-medicine-delivery-app-development-for-pharmacy-store/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-on-demand-medicine-delivery-app-development-for-pharmacy-store-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-on-demand-medicine-delivery-solution-for-pharmacies-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/on-demand-medicine-delivery-solution-for-pharmacies/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-on-demand-medicine-delivery-solution-for-pharmacies-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-online-grocery-business-transformation-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/online-grocery-business-transformation/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-online-grocery-business-transformation-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-prescription-refills-and-delivery-with-online-medicine-delivery-platform-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/prescription-refills-and-delivery-with-online-medicine-delivery-platform/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-prescription-refills-and-delivery-with-online-medicine-delivery-platform-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-restaurant-pickup-delivery-best-practices-with-yelowsoft-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/restaurant-pickup-delivery-best-practices-with-yelowsoft/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-restaurant-pickup-delivery-best-practices-with-yelowsoft-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-revolution-of-on-demand-delivery-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/revolution-of-on-demand-delivery-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-revolution-of-on-demand-delivery-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-rise-of-electric-vehicles-in-delivery-businesses-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/rise-of-electric-vehicles-in-delivery-businesses/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-rise-of-electric-vehicles-in-delivery-businesses-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-saas-business-model-for-on-demand-startup-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/saas-business-model-for-on-demand-startup/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-saas-business-model-for-on-demand-startup-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-the-secret-sauce-restaurant-profit-boost-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/the-secret-sauce-restaurant-profit-boost/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-the-secret-sauce-restaurant-profit-boost-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-things-to-consider-before-developing-an-on-demand-food-delivery-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/things-to-consider-before-developing-an-on-demand-food-delivery-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-things-to-consider-before-developing-an-on-demand-food-delivery-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-through-on-demand-parcel-delivery-digital-transformation-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/through-on-demand-parcel-delivery-digital-transformation/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-through-on-demand-parcel-delivery-digital-transformation-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-top-features-for-restaurant-delivery-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/top-features-for-restaurant-delivery-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-top-features-for-restaurant-delivery-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-white-label-solution-for-food-delivery-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/white-label-solution-for-food-delivery-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-white-label-solution-for-food-delivery-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowxpress-a-powerful-solution-for-all-your-delivery-needs-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowxpress-a-powerful-solution-for-all-your-delivery-needs/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowxpress-a-powerful-solution-for-all-your-delivery-needs-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowxpress-grocery-delivery-headless-technology-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowxpress-grocery-delivery-headless-technology/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowxpress-grocery-delivery-headless-technology-index-mdx" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-clone-single-js": () => import("./../../../src/templates/clone-single.js" /* webpackChunkName: "component---src-templates-clone-single-js" */),
  "component---src-templates-solutions-single-js": () => import("./../../../src/templates/solutions-single.js" /* webpackChunkName: "component---src-templates-solutions-single-js" */)
}

